import React, { useState } from "react";
import styled from "styled-components";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Icons
import { ArrowIconNext } from "../icons/arrows";

// Components
import { LightboxGallery } from "../images/lightbox-gallery";

const PressContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  position: relative;

  width: 100%;
  height: 100%;

  overflow-y: scroll;

  padding: 80px 40px;

  color: #f8f1e8;

  @media (max-width: 767px) {
    padding: 35px 30px 30px 30px;
  }

  & h1 {
    font-size: 10px;
    line-height: 28px;
    letter-spacing: 0.15em;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  & .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 40px;

    @media (max-width: 767px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    width: 100%;

    & .left-column {
      grid-column: 3 / 7;

      & .content-container {
        max-width: 300px;
        margin: 0 60px 0 auto;

        & img {
          cursor: pointer;
        }
      }

      @media (max-width: 767px) {
        grid-column: unset;
      }
    }

    & .right-column {
      grid-column: 7 / 11;

      & .content-container {
        max-width: 440px;
      }

      @media (max-width: 767px) {
        grid-column: unset;
      }
    }
  }

  & ol {
    & li {
      width: fit-content;

      transition: 200ms color ease;

      &.single-article {
        cursor: pointer;

        &.active {
          color: rgba(248, 241, 232, 0.5);
        }
      }

      &.single-award {
        cursor: default;

        & a {
          cursor: pointer;

          &:hover {
            color: rgba(248, 241, 232, 0.5);
          }
        }
      }
    }
  }

  & .awards-container {
    margin: 0 0 2em 0;
  }

  & .body-text {
    letter-spacing: 0.02em;

    & p {
      margin: 0;
    }
  }

  & .pdf-download-container {
    & p {
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.02em;
      margin: 0;
    }

    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;

      & svg {
        width: 34px;
        height: auto;

        margin: 0 10px 0 0;
      }
    }
  }

  & .mobile-header {
    display: none;
    text-align: center;
    margin: 0 0 30px 0;

    & h1 {
      font-size: 18px;
      line-height: 36px;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }
`;

export const Press = ({ data }) => {
  let isDesktop = useMediaQuery("(min-width: 768px)");

  const [activePressIndex, setActivePressIndex] = useState(0);
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxContent, setLightboxContent] = useState(null);

  const awards = data.awards.map((award, index) => (
    <li key={`single_award_${index}`} className="single-award">
      <div
        className="body-text"
        dangerouslySetInnerHTML={{
          __html: award.award.html,
        }}
      />
    </li>
  ));

  const articles = data.body.map((article, index) => (
    <li
      key={`single_article_${index}`}
      className={`single-article ${activePressIndex === index ? `active` : ``}`}
      onMouseEnter={() => setActivePressIndex(index)}
      onClick={() => {
        setActivePressIndex(index);
        setLightboxOpen(true);
        setLightboxContent(data.body[activePressIndex]);
      }}
    >
      <div
        className="body-text"
        dangerouslySetInnerHTML={{
          __html: article.primary.article_title.html,
        }}
      />
    </li>
  ));

  return (
    <>
      <PressContainer id="press">
        <div className="grid">
          <div className="left-column">
            <div className="content-container">
              {isDesktop && data.body[activePressIndex] !== undefined && (
                <>
                  {data.body[activePressIndex].primary.thumbnail.fluid !==
                    null && (
                    <img
                      srcSet={
                        data.body[activePressIndex].primary.thumbnail.fluid
                          .srcSetWebp
                      }
                      src={
                        data.body[activePressIndex].primary.thumbnail.fluid
                          .srcWebp
                      }
                      alt={data.body[activePressIndex].primary.thumbnail.alt}
                      loading={`lazy`}
                      onClick={() => {
                        setLightboxOpen(true);
                        setLightboxContent(data.body[activePressIndex]);
                      }}
                    />
                  )}

                  <div className="pdf-download-container">
                    <p>
                      <a
                        target="_blank"
                        download
                        href={
                          data.body[activePressIndex].primary.pdf_download.url
                        }
                      >
                        <ArrowIconNext fill={`#F8F1E8`} />
                        Download PDF
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="right-column">
            <div className="content-container">
              <div className="mobile-header">
                <h1 className="uppercase soehne-light">Press</h1>
              </div>

              <div className="awards-container">
                <h1 className="uppercase soehne-buch">Awards</h1>
                <ol>{awards}</ol>
              </div>

              <div className="press-container">
                <h1 className="uppercase soehne-buch">Press</h1>
                <ol>{articles}</ol>
              </div>
            </div>
          </div>
        </div>
      </PressContainer>

      {isLightboxOpen && (
        <LightboxGallery
          isLightboxOpen={isLightboxOpen}
          setLightboxOpen={setLightboxOpen}
          content={lightboxContent}
        />
      )}
    </>
  );
};
