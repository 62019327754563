import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Div100vh, { use100vh } from "react-div-100vh";
import { useKeyPressEvent } from "react-use";

// Utils
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import kebabCase from "lodash.kebabcase";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Components
import { ImageWithText } from "../layout/image-with-text";
import { Contact } from "./contact";
import { Press } from "./press";

// Navigation
import { DesktopNavigation } from "../navigation/desktop-navigation";

const PageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const OverflowBar = styled.div`
  position: fixed;
  top: ${props => props.top};
  left: 0;
  right: 0;
  bottom: ${props => props.bottom};

  height: 65px;

  background-color: ${props => props.bgColor};
  z-index: 10;
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 0;

  color: ${props => props.textColor};

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  /* & .react-slideshow-fade-images-wrap {
    & div[aria-hidden="true"] {
      & p,
      & a,
      & input {
      }
    }
  } */
`;

const NextPageLink = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  width: calc(((100vw - 400px) / 2) - 40px);
  min-width: 200px;
  height: 75%;

  right: 0;

  z-index: 100;

  & a {
    display: block;
    width: 100%;
    height: 100%;


    cursor: url("/icons/arrow-next-${props =>
      props.color}@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-next-${props => props.color}@1x.png") 1x,
          url("/icons/arrow-next-${props => props.color}@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-next-${props => props.color}.svg"), 50 0, pointer;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;



  width: calc(((100vw - 400px) / 2) - 40px);
  min-width: 200px;
  height: 75%;

  &.studio-arrow {
    width: calc(((100vw - 650px) / 2) - 40px);

    &.contact-arrow{
      width: calc(((100vw - 1000px) / 2) - 40px);
    }
  }

  &.title-slide{
    width: 100%;
  }

  z-index: 100;

  &.previous {
    left: 0;

    cursor: url("/icons/arrow-prev-${props =>
      props.color}@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-prev-${props => props.color}@1x.png") 1x,
          url("/icons/arrow-prev-${props => props.color}@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-prev-${props => props.color}.svg"), 50 0, pointer;
  }

  &.next {
    right: 0;

    cursor: url("/icons/arrow-next-${props =>
      props.color}@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-next-${props => props.color}@1x.png") 1x,
          url("/icons/arrow-next-${props => props.color}@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-next-${props => props.color}.svg"), 50 0, pointer;
  }

  &.disabled {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &.small-image-with-text-slide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 95px 40px 75px 40px;
    overflow-y: scroll;

    & .inner-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      width: 100%;

      & .image-container {
        & img {
          max-width: 215px;

          margin: 75px 0 35px 0;

          @media (max-width: 1340px) {
            max-width: 200px;
          }
        }
      }

      & .spacer {
        max-width: 215px;
        width: 100%;

        @media (max-width: 1340px) {
          max-width: 200px;
        }
      }

      & .text-container {
        max-width: 730px;
        margin: 0 50px;
        text-align: center;

        & .text-title-slide {
          margin: 0 0 35px 0;

          &.font-render {
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
            text-rendering: optimizeLegibility !important;
            -webkit-font-smoothing: antialiased !important;
          }

          & h2 {
            font-size: 16px;
            line-height: 34px;
            letter-spacing: 0.15em;
          }
        }
      }
    }
  }

  &.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.bgColor};

    padding: 80px 40px;
    overflow-y: scroll;

    & h1 {
      font-size: 70px;
      line-height: 95px;
      letter-spacing: 0.15em;

      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;

      @media (max-width: 1450px) {
        font-size: 60px;
        line-height: 80px;
      }

      @media (max-height: 800px) {
        font-size: 50px;
        line-height: 70px;
      }
    }
  }

  &.text-with-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 80px 40px;

    overflow-y: scroll;

    & .text-container {
      max-width: 730px;
      margin: 0 auto;
      text-align: center;

      & .text-title-slide {
        margin: 0 0 55px 0;

        @media (max-height: 800px) {
          margin: 0 0 35px 0;
        }

        &.font-render {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
          text-rendering: optimizeLegibility !important;
          -webkit-font-smoothing: antialiased !important;
        }

        & h2 {
          font-size: 16px;
          line-height: 34px;
          letter-spacing: 0.15em;
        }
      }
    }
  }

  &.image-slide {
    height: 100%;
    padding: 95px 40px 75px 40px;
  }

  &.image-with-text-slide {
    height: 100%;
    padding: 95px 40px 75px 40px;
  }

  &.two-images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 6;
      }

      & .image-right {
        grid-column: 9 / 13;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
          max-height: calc(100% - 100px);
        }
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 5;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
          max-height: calc(100% - 100px);
        }
      }

      & .image-right {
        grid-column: 7 / 13;
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & .image-captions {
      margin: 0 0 0 auto;
    }

    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 330px;

    align-self: flex-end;
    margin: 0 0 -5px 0;

    & .image-caption {
      display: grid;
      grid-template-columns: 35px auto;
      grid-column-gap: 30px;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

export const OverviewDesktop = ({ data, location, slideSections, bgColor }) => {
  const slideRef = useRef();
  const height = use100vh();
  const [nextSlideNumber, setNextSlideNumber] = useState(1);
  const [activeSlideId, setActiveSlideId] = useState(slideSections[0]);

  const goToSlide = id => {
    // const index = slideSections.indexOf(id);
    const index = slideSections.findIndex(i => i.id === id);
    slideRef.current.goTo(index);
  };

  const [textOpacity, setTextOpacity] = useState(1);
  useEffect(() => {
    if (textOpacity === 0) {
      const showText = setTimeout(() => {
        setTextOpacity(1);
      }, 650);
      return () => clearTimeout(showText);
    }
  }, [textOpacity]);

  useEffect(() => {
    if (
      location !== undefined &&
      location !== null &&
      location.state !== undefined &&
      location.state !== null &&
      location.state.slideId !== undefined &&
      location.state.slideId !== null
    ) {
      // const incomingSlide = slideSections.indexOf(location.state.slideId);
      const incomingSlide = slideSections.findIndex(
        i => i.id === location.state.slideId.id
      );

      if (incomingSlide !== -1) {
        setActiveSlideId(location.state.slideId);
      } else {
        setActiveSlideId(slideSections[0]);
      }
    }
  }, [location]);

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined) {
      slideRef.current.goNext();
    }
  };

  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  const slides = data.prismicOverviewSection.data.sections
    .map((content, outerIndex) => {
      if (content.page.document.type === "page") {
        return content.page.document.data.body.map((content, index) => {
          if (content.slice_type === "title") {
            return (
              <Slide
                className="title"
                bgColor={data.prismicOverviewSection.data.background_color}
                key={`single_title_container_${content.id}_${index}`}
              >
                <div
                  className={`uppercase center-text soehne-extra-light font-render`}
                  dangerouslySetInnerHTML={{
                    __html: content.primary.title_slide.html,
                  }}
                />
              </Slide>
            );
          }
          if (content.slice_type === "text") {
            return (
              <Slide
                className="text-with-title"
                key={`single_text_container_${content.id}_${index}`}
              >
                <div className="text-container">
                  <div
                    className={`text-title-slide uppercase center-text soehne-light ${
                      data.prismicOverviewSection.data.title.text === "Studio"
                        ? `font-render`
                        : ``
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text_title.html,
                    }}
                  />
                  <div
                    className="text-slide"
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text.html,
                    }}
                  />
                </div>
              </Slide>
            );
          }
          if (content.slice_type === "single_image") {
            return (
              <Slide
                key={`single_project_image_container_${content.id}_${index}`}
                className="image-slide"
              >
                <ImageContainer>
                  <AspectRatioImageContainer image={null} padding={71}>
                    {/* {(index === nextSlideNumber || */}
                    {/* // index === nextSlideNumber + 1) && ( */}
                    <>
                      {content.primary.image.fluid !== null && (
                        <img
                          srcSet={content.primary.image.fluid.srcSetWebp}
                          src={content.primary.image.fluid.srcWebp}
                          alt={content.primary.image.alt}
                          loading={`lazy`}
                        />
                      )}
                    </>
                    {/* // )} */}
                  </AspectRatioImageContainer>
                </ImageContainer>
              </Slide>
            );
          }
          if (content.slice_type === "two_images") {
            return (
              <Slide
                key={`single_project_image_container_${content.id}_${index}`}
                className={`image-slide two-images small-${kebabCase(
                  content.primary.small_image
                )}`}
              >
                <ImageContainer className="image-left">
                  <div className="image-container">
                    <AspectRatioImageContainer image={null} padding={133.333}>
                      {content.primary.image_left.fluid !== null && (
                        <img
                          srcSet={content.primary.image_left.fluid.srcSetWebp}
                          src={content.primary.image_left.fluid.srcWebp}
                          alt={content.primary.image_left.alt}
                          loading={`lazy`}
                        />
                      )}
                    </AspectRatioImageContainer>
                  </div>
                  {content.primary.small_image === "Image Left" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout">Above</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout">Right</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </ImageContainer>
                <ImageContainer className="image-right">
                  <div className="image-container">
                    <AspectRatioImageContainer image={null} padding={133.333}>
                      {/* {index === nextSlideNumber || */}
                      {/* // (index === nextSlideNumber + 1 && ( */}
                      <>
                        {content.primary.image_right.fluid !== null && (
                          <img
                            srcSet={
                              content.primary.image_right.fluid.srcSetWebp
                            }
                            src={content.primary.image_right.fluid.srcWebp}
                            alt={content.primary.image_right.alt}
                            loading={`lazy`}
                          />
                        )}
                      </>
                      {/* ))} */}
                    </AspectRatioImageContainer>
                  </div>
                  {content.primary.small_image === "Image Right" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout">Left</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout">Above</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </ImageContainer>
              </Slide>
            );
          }

          if (content.slice_type === "image_with_text") {
            return (
              <Slide
                key={`single_project_image_with_text_container_${content.id}_${index}`}
                className="image-with-text-slide"
              >
                <ImageWithText
                  content={content}
                  pageHeight={height}
                  textOpacity={textOpacity}
                  playing={activeSlideId.id === content.id ? true : false}
                  // loadContent={
                  //   activeSlideId === content.id ||
                  //   nextSlideNumber === slideSections.indexOf(content.id)
                  // }
                />
              </Slide>
            );
          }

          if (content.slice_type === "small_image_with_text") {
            return (
              <Slide
                key={`single_project_small_image_with_text_container_${content.id}_${index}`}
                className="small-image-with-text-slide"
              >
                <div className="inner-container">
                  <div className="image-container">
                    {content.primary.image.fluid !== null && (
                      <img
                        srcSet={content.primary.image.fluid.srcSetWebp}
                        src={content.primary.image.fluid.srcWebp}
                        alt={content.primary.image.alt}
                        loading={`lazy`}
                      />
                    )}
                  </div>
                  <div className="text-container">
                    <div
                      className={`text-title-slide uppercase center-text soehne-light ${
                        data.prismicOverviewSection.data.title.text === "Studio"
                          ? `font-render`
                          : ``
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: content.primary.section_title.html,
                      }}
                    />
                    <div
                      className="text-slide"
                      dangerouslySetInnerHTML={{
                        __html: content.primary.text.html,
                      }}
                    />
                  </div>
                  <div className="spacer" />
                </div>
              </Slide>
            );
          }
        });
      } else if (content.page.document.type === "contact") {
        return (
          <Contact
            data={content.page.document.data}
            key={`single_contact_page_${content.id}_${outerIndex}`}
          />
        );
      } else if (content.page.document.type === "press") {
        return (
          <Press
            data={content.page.document.data}
            key={`single_press_page_${content.id}_${outerIndex}`}
          />
        );
      } else {
        return null;
      }
    })
    .filter(content => content !== null);

  return (
    <Div100vh>
      <PageContainer>
        <OverflowBar bgColor={bgColor} top={0} bottom={`unset`} />

        <GalleryContainer
          textColor={
            data.prismicOverviewSection.data.title.text === `Studio`
              ? `rgba(248, 241, 232, 1)`
              : `#000`
          }
        >
          {slideRef.current !== undefined &&
            slideRef.current.state.index !== 0 && (
              <Arrow
                tabIndex="0"
                aria-label="Previous Slide"
                className={`previous
                ${slideRef.current.state.index === 0 ? `title-slide` : ``}
                ${location.pathname.includes(`/studio`) ? `studio-arrow` : ``}
                ${activeSlideId.section === "contact" ? `contact-arrow` : ``}
                `}
                onClick={() => {
                  slideRef.current.goBack();
                  setTextOpacity(0);
                }}
                color={
                  data.prismicOverviewSection.data.title.text === `Studio`
                    ? `light`
                    : `dark`
                }
              />
            )}

          {slideRef.current !== undefined &&
            nextSlideNumber + 1 !== slides.flat().length && (
              <Arrow
                tabIndex="0"
                aria-label="Next Slide"
                className={`next
                ${slideRef.current.state.index === 0 ? `title-slide` : ``}
                ${location.pathname.includes(`/studio`) ? `studio-arrow` : ``}
                ${activeSlideId.section === "contact" ? `contact-arrow` : ``}
                `}
                onClick={() => {
                  slideRef.current.goNext();
                  setTextOpacity(0);
                }}
                color={
                  data.prismicOverviewSection.data.title.text === `Studio`
                    ? `light`
                    : `dark`
                }
              />
            )}

          {data.prismicOverviewSection.data.title.text !== `Studio` &&
            nextSlideNumber + 1 === slides.flat().length && (
              <NextPageLink color={`dark`} tabIndex="0">
                <Link to={`/projects`} aria-label="Visit Projects" />
              </NextPageLink>
            )}

          <Fade
            ref={slideRef}
            defaultIndex={
              location !== undefined &&
              location !== null &&
              location.state !== undefined &&
              location.state !== null &&
              location.state.slideId !== undefined &&
              location.state.slideId !== null
                ? slideSections.findIndex(
                    i => i.id === location.state.slideId.id
                  ) !== -1
                  ? slideSections.findIndex(
                      i => i.id === location.state.slideId.id
                    )
                  : 0
                : 0
            }
            transitionDuration={1000}
            easing={`ease`}
            arrows={false}
            autoplay={false}
            pauseOnHover={false}
            infinite={false}
            canSwipe={true}
            onChange={(previous, next) => {
              setNextSlideNumber(next);
              setActiveSlideId(slideSections[next]);
            }}
          >
            {slides.flat()}
          </Fade>
        </GalleryContainer>

        <DesktopNavigation
          location={location}
          section={data.prismicOverviewSection.data.title.text}
          goToSlide={goToSlide}
          activeSlideId={activeSlideId}
          bgColor={data.prismicOverviewSection.data.background_color}
          textColor={
            data.prismicOverviewSection.data.title.text === `Studio`
              ? `rgba(248, 241, 232, 1)`
              : `#000`
          }
        />

        <OverflowBar bgColor={bgColor} top={`unset`} bottom={0} />
      </PageContainer>
    </Div100vh>
  );
};
