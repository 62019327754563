import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Based off https://github.com/a-rolland/react-crossfade-responsive

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  object-fit: cover;
  opacity: 0;

  &.active {
    opacity: 1;
    transition: opacity ${props => props.transitionDelay};
  }
  &.inactive {
    opacity: 0;
    transition: opacity ${props => props.transitionDelay};
  }
`;

export const CrossfadeContainer = ({ images, id, playing }) => {
  const carouselLength = images.filter(image => image.image.fluid !== null)
    .length;
  const [currentCarouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (playing === true) {
      const interval = setInterval(() => {
        setCarouselIndex(currentCarouselIndex => {
          const newIndex =
            currentCarouselIndex + 1 >= carouselLength
              ? 0
              : currentCarouselIndex + 1;
          return newIndex;
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [playing, currentCarouselIndex]);

  const carousel = images
    .filter(image => image.image.fluid !== null)
    .map((image, index) => (
      <Image
        className={`single-image ${
          currentCarouselIndex === index ? "active" : "inactive"
        }`}
        key={`single_image_with_text_image_${index}_${id}`}
        transitionDelay={"3s"}
      >
        <div className="inner-image-container">
          {image.image.fluid !== null && (
            <img
              srcSet={image.image.fluid.srcSetWebp}
              src={image.image.fluid.srcWebp}
              alt={image.image.alt}
              loading={`lazy`}
            />
          )}
        </div>
      </Image>
    ));

  return <Container>{carousel}</Container>;
};
