import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const NavigationContainer = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const List = styled.li`
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.15em;

  margin: 0 20px 0 0;
  cursor: pointer;

  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;

  transition: 250ms color ease;

  &.current {
    color: rgba(248, 241, 232, 0.5);
  }

  &:hover {
    color: rgba(248, 241, 232, 0.5);

    & ol {
      color: rgba(248, 241, 232, 1);
    }
  }

  & button {
    font-size: 10px;
    line-height: 21px;
    letter-spacing: 0.15em;

    cursor: pointer;

    font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    transition: 250ms color ease;

    &.current {
      color: rgba(248, 241, 232, 0.5);
    }

    &:hover {
      color: rgba(248, 241, 232, 0.5);

      & ol {
        color: #000;
      }
    }
  }

  & ol {
    position: absolute;
    bottom: 45px;

    background-color: ${props => props.bgColor};
    opacity: 0.9;
    padding: 15px 20px 20px 20px;
    margin: 0 0 0 -20px;

    display: ${props => props.isSubMenuVisible};

    & li {
      margin: 0;
      line-height: 22px;
    }
  }
`;

export const StudioNavigation = ({ goToSlide, activeSlideId }) => {
  const data = useStaticQuery(graphql`
    {
      prismicOverviewSection(uid: { eq: "studio" }) {
        data {
          title {
            text
          }
          sections {
            page {
              type
              document {
                ... on PrismicProjects {
                  id
                  type
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPress {
                  id
                  type
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContact {
                  id
                  type
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  type
                  data {
                    title {
                      text
                    }
                    body {
                      ... on PrismicPageBodyTitle {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyText {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodySingleImage {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyImageWithText {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodySmallImageWithText {
                        id
                        slice_type
                      }
                    }
                  }
                }
                ... on PrismicProjects {
                  id
                  data {
                    title {
                      text
                    }
                    projects {
                      project {
                        url
                        document {
                          ... on PrismicProject {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const navigation = data.prismicOverviewSection.data.sections.map(
    (page, index) => {
      if (page.page.type === "page") {
        const allSlideIds = page.page.document.data.body.map(item => item.id);
        const firstSlideId = allSlideIds[0];

        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className={`uppercase ${
              allSlideIds.includes(activeSlideId.id) ? `current` : ``
            }`}
          >
            <button
              className="uppercase"
              onClick={() => goToSlide(firstSlideId)}
            >
              {page.page.document.data.title.text}
            </button>
          </List>
        );
      } else {
        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className={`uppercase ${
              page.page.document.id === activeSlideId.id ? `current` : ``
            }`}
          >
            <button
              className="uppercase"
              onClick={() => goToSlide(page.page.document.id)}
            >
              {page.page.document.data.title.text}{" "}
            </button>
          </List>
        );
      }
    }
  );

  return <NavigationContainer>{navigation}</NavigationContainer>;
};
