import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Utils
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import kebabCase from "lodash.kebabcase";

// Components
import { ImageWithTextMobile } from "../layout/image-with-text-mobile";
import { Contact } from "./contact";
import { Press } from "./press";

// Navigation
import { MobileNavigation } from "../navigation/mobile-navigation";

const PageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const HeaderBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 65px;

  background-color: ${props => props.bgColor};
  z-index: 10;
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  padding: 0 0 75px 0;

  color: ${props => props.textColor};

  overflow-x: hidden;
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;

  &.small-image-with-text-slide {
    padding: 80px 30px 0 30px;

    & .image-container {
      & img {
        max-width: 300px;
        margin: 0 auto;
      }
    }

    & .text-container {
      max-width: 730px;
      margin: 0 auto;

      & .text-title-slide {
        margin: 35px 0;

        &.font-render {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
          text-rendering: optimizeLegibility !important;
          -webkit-font-smoothing: antialiased !important;
        }

        & p {
          font-size: 12px;
          line-height: 34px;
          letter-spacing: 0.15em;
        }

        & h2 {
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.15em;
        }
      }
    }
  }

  &.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    height: ${props => props.height}px;

    padding: 0 20px;
    margin: 0;

    & h1 {
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 0.15em;

      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
    }
  }

  &.text-with-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 65px 30px 30px 30px;

    & .text-container {
      max-width: 730px;
      margin: 0 auto;

      & .text-title-slide {
        margin: 0 0 35px 0;

        &.font-render {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
          text-rendering: optimizeLegibility !important;
          -webkit-font-smoothing: antialiased !important;
        }

        & h2 {
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.15em;
        }
      }
    }
  }

  &.image-slide {
    height: 100%;
    padding: 80px 30px 0 30px;
  }

  &.image-with-text-slide {
    height: 100%;
    padding: 80px 30px 0 30px;
  }

  &.two-images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 6;
      }

      & .image-right {
        grid-column: 9 / 13;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
        }
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 5;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
        }
      }

      & .image-right {
        grid-column: 7 / 13;
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & .image-captions {
      margin: 0 0 0 auto;
    }

    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 330px;

    align-self: flex-end;

    & .image-caption {
      display: grid;
      grid-template-columns: 35px auto;
      grid-column-gap: 30px;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

export const OverviewMobile = ({ data, location, bgColor }) => {
  const height = use100vh();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        document.querySelector(`${location.hash}`).scrollIntoView();
      }, 0);
    }
  }, [location]);

  const slides = data.prismicOverviewSection.data.sections
    .map((content, outerIndex) => {
      if (content.page.document.type === "page") {
        return content.page.document.data.body.map((content, index) => {
          if (content.slice_type === "title") {
            return (
              <Slide
                className="title"
                bgColor={data.prismicOverviewSection.data.background_color}
                key={`single_title_container_${content.id}_${index}`}
                height={height}
                id={`item-${content.id}`}
              >
                <div
                  className={`uppercase center-text soehne-extra-light font-render`}
                  dangerouslySetInnerHTML={{
                    __html: content.primary.title_slide.html,
                  }}
                />
              </Slide>
            );
          }
          if (content.slice_type === "text") {
            return (
              <Slide
                className="text-with-title"
                key={`single_text_container_${content.id}_${index}`}
                id={`item-${content.id}`}
              >
                <div className="text-container">
                  <div
                    className={`text-title-slide uppercase center-text soehne-light ${
                      data.prismicOverviewSection.data.title.text === "Studio"
                        ? `font-render`
                        : ``
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text_title.html,
                    }}
                  />
                  <div
                    className="text-slide"
                    dangerouslySetInnerHTML={{
                      __html: content.primary.text.html,
                    }}
                  />
                </div>
              </Slide>
            );
          }
          if (content.slice_type === "single_image") {
            return (
              <Slide
                key={`single_project_image_container_${content.id}_${index}`}
                className="image-slide"
                id={`item-${content.id}`}
              >
                <ImageContainer>
                  <AspectRatioImageContainer image={null} padding={71}>
                    {content.primary.image.fluid !== null && (
                      <img
                        srcSet={content.primary.image.fluid.srcSetWebp}
                        src={content.primary.image.fluid.srcWebp}
                        alt={content.primary.image.alt}
                        loading={`lazy`}
                      />
                    )}
                  </AspectRatioImageContainer>
                </ImageContainer>
              </Slide>
            );
          }
          if (content.slice_type === "two_images") {
            return (
              <Slide
                key={`single_project_image_container_${content.id}_${index}`}
                className={`image-slide two-images small-${kebabCase(
                  content.primary.small_image
                )}`}
                id={`item-${content.id}`}
              >
                <ImageContainer className="image-left">
                  <div className="image-container">
                    <AspectRatioImageContainer image={null} padding={133.333}>
                      {content.primary.image_left.fluid !== null && (
                        <img
                          srcSet={content.primary.image_left.fluid.srcSetWebp}
                          src={content.primary.image_left.fluid.srcWebp}
                          alt={content.primary.image_left.alt}
                          loading={`lazy`}
                        />
                      )}
                    </AspectRatioImageContainer>
                  </div>
                  {content.primary.small_image === "Image Left" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout">Above</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout">Right</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </ImageContainer>
                <ImageContainer className="image-right">
                  <div className="image-container">
                    <AspectRatioImageContainer image={null} padding={133.333}>
                      {content.primary.image_right.fluid !== null && (
                        <img
                          srcSet={content.primary.image_right.fluid.srcSetWebp}
                          src={content.primary.image_right.fluid.srcWebp}
                          alt={content.primary.image_right.alt}
                          loading={`lazy`}
                        />
                      )}
                    </AspectRatioImageContainer>
                  </div>
                  {content.primary.small_image === "Image Right" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout">Left</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout">Above</p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </ImageContainer>
              </Slide>
            );
          }
          if (content.slice_type === "image_with_text") {
            return (
              <Slide
                key={`single_project_image_with_text_container_${content.id}_${index}`}
                className="image-with-text-slide"
                borderColor={
                  data.prismicOverviewSection.data.title.text === `Studio`
                    ? `rgba(248, 241, 232, 1);
        `
                    : `#000`
                }
                id={`item-${content.id}`}
              >
                <ImageWithTextMobile content={content} playing={true} />
              </Slide>
            );
          }

          if (content.slice_type === "small_image_with_text") {
            return (
              <Slide
                key={`single_project_small_image_with_text_container_${content.id}_${index}`}
                className="small-image-with-text-slide"
              >
                <div className="inner-container">
                  <div className="image-container">
                    {content.primary.image.fluid !== null && (
                      <img
                        srcSet={content.primary.image.fluid.srcSetWebp}
                        src={content.primary.image.fluid.srcWebp}
                        alt={content.primary.image.alt}
                        loading={`lazy`}
                      />
                    )}
                  </div>
                  <div className="text-container">
                    <div
                      className={`text-title-slide uppercase center-text soehne-light ${
                        data.prismicOverviewSection.data.title.text === "Studio"
                          ? `font-render`
                          : ``
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: content.primary.section_title.html,
                      }}
                    />
                    <div
                      className="text-slide"
                      dangerouslySetInnerHTML={{
                        __html: content.primary.text.html,
                      }}
                    />
                  </div>
                </div>
              </Slide>
            );
          }
        });
      } else if (content.page.document.type === "contact") {
        return (
          <Contact
            key={`single_contact_block_${content.id}_${outerIndex}`}
            data={content.page.document.data}
            id={`item-${content.id}`}
          />
        );
      } else if (content.page.document.type === "press") {
        return (
          <Press
            key={`single_press_block_${content.id}_${outerIndex}`}
            data={content.page.document.data}
            id={`item-${content.id}`}
          />
        );
      } else {
        return null;
      }
    })
    .filter(content => content !== null);

  return (
    <PageContainer>
      <HeaderBar bgColor={bgColor} />
      <GalleryContainer
        textColor={
          data.prismicOverviewSection.data.title.text === `Studio`
            ? `rgba(248, 241, 232, 1)`
            : `#000`
        }
      >
        {slides}
      </GalleryContainer>

      <MobileNavigation
        location={location.pathname}
        bgColor={bgColor}
        section={data.prismicOverviewSection.data.title.text}
        pageType={data.prismicOverviewSection.data.title.text}
        textColor={
          data.prismicOverviewSection.data.title.text === `Studio`
            ? `rgba(248, 241, 232, 1)`
            : `#000`
        }
      />
    </PageContainer>
  );
};
