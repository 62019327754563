import React from "react";
import styled from "styled-components";

// Hooks
import { useMeasure } from "react-use";

// Utils
import { CrossfadeContainer } from "../containers/crossfade-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const ImageWithTextSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: 100%;

  @media (orientation: portrait) {
    justify-content: center;
  }

  & .content-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  & .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: ${props => props.maxImageHeight};

    & > div {
      width: 100%;
      height: 100%;

      & .single-image {
        & .inner-image-container {
          width: 100%;
          height: 100%;
        }

        & img {
          max-height: 100%;
          width: 100%;

          object-fit: contain;
          object-position: top;
        }
      }
    }
  }

  & .text-container {
    flex: 1;

    opacity: ${props => props.textOpacity};
    transition: 300ms opacity linear;

    text-align: ${props => props.textAlign};
    max-width: ${props => props.textMaxWidthDesktop};
    margin: 45px auto 0 auto;

    & .title-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      & p,
      & h2 {
        margin: 0;
      }

      & p {
        font-size: 12px;
        line-height: 34px;
        letter-spacing: 0.15em;
      }

      & h2 {
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.15em;
      }

      & .line-spacer {
        width: 60px;
        border-bottom: 1px solid ${props => props.borderColor};
        margin: 0 10px;
      }
    }

    & .text-section.grid {
      display: grid;
      grid-template-columns: auto 380px;
      grid-column-gap: 45px;
    }

    & .small-text {
      font-size: 14px;
      line-height: 24px;

      letter-spacing: 0.02em;

      & ul {
        padding: 0 0 0 40px;

        & li {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          &::before {
            content: "";
            margin-left: -40px;
            margin-right: 10px;

            display: inline-block;
            width: 32px;
            border-bottom: 1px solid ${props => props.borderColor};
          }
        }
      }
    }
  }
`;

export const ImageWithText = ({
  content,
  pageHeight,
  textOpacity,
  playing,
  loadContent,
}) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  return (
    <ImageWithTextSlideContainer
      // className={loadContent ? `load-content` : `no-content`}
      imageFlex={`100%`}
      textAlign={
        content.primary.small_text.text.length >= 1 ? `left` : `center`
      }
      textMaxWidthDesktop={
        content.primary.small_text.text.length >= 1 ? `1060px` : `900px`
      }
      maxImageHeight={`calc(${pageHeight}px - ${height}px - 75px - 75px)`}
      textOpacity={textOpacity}
    >
      <div className="content-container">
        <div className="image-container">
          {/* {loadContent && ( */}
          <AspectRatioImageContainer image={null} padding={71}>
            <CrossfadeContainer
              images={content.items}
              id={content.id}
              playing={playing}
            />
          </AspectRatioImageContainer>
          {/* // )} */}
        </div>
      </div>
      <div className="content-container" ref={ref}>
        <div className="text-container">
          <div className="title-bar">
            <p className="section-number">{content.primary.section_number}</p>
            {content.primary.section_number !== null && (
              <div className="line-spacer" />
            )}
            <div
              className="section-title uppercase soehne-light"
              dangerouslySetInnerHTML={{
                __html: content.primary.section_title.html,
              }}
            />
          </div>
          <div
            className={`text-section ${content.primary.small_text.text.length >=
              1 && `grid`}`}
          >
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
            <div
              className="small-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.small_text.html,
              }}
            />
          </div>
        </div>
      </div>
    </ImageWithTextSlideContainer>
  );
};
