import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageType } from "../components/context/page-type";
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { OverviewDesktop } from "../components/sections/overview-desktop";
import { OverviewMobile } from "../components/sections/overview-mobile";

const OverviewPage = ({ data, location }) => {
  let isDesktop = useMediaQuery("(min-width: 768px)");

  const [pageType, setPageType] = useContext(PageType);
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageType(data.prismicOverviewSection.data.title.text);
  }, [data]);

  useEffect(() => {
    setPageBackgroundColor(data.prismicOverviewSection.data.background_color);
  }, [data]);

  // const slideSections = Array.from(
  //   data.prismicOverviewSection.data.sections.map(content => {
  //     if (content.page.document.type === "page") {
  //       return content.page.document.data.body.map(item => item.id);
  //     } else {
  //       return content.page.document.id;
  //     }
  //   })
  // ).flat();

  const slideSections = Array.from(
    data.prismicOverviewSection.data.sections.map(content => {
      if (content.page.document.type === "page") {
        return content.page.document.data.body.map(
          item =>
            (item = {
              id: item.id,
              section: content.page.document.data.title.text.toLowerCase(),
            })
        );
      } else {
        return (content = {
          id: content.page.document.id,
          section: content.page.document.data.title.text.toLowerCase(),
        });
      }
    })
  ).flat();

  return (
    <>
      <Helmet
        title={`${data.prismicOverviewSection.data.title.text} | Dan Fink Studio`}
        meta={[
          {
            name: "title",
            content: `${data.prismicOverviewSection.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "og:title",
            content: `${data.prismicOverviewSection.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicOverviewSection.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "og:url",
            content: `https://danfinkstudio.com${data.prismicOverviewSection.url}`,
          },
          {
            property: "twitter:url",
            content: `https://danfinkstudio.com${data.prismicOverviewSection.url}`,
          },
        ]}
      />

      {isDesktop ? (
        <OverviewDesktop
          data={data}
          location={location}
          slideSections={slideSections}
          bgColor={data.prismicOverviewSection.data.background_color}
        />
      ) : (
        <OverviewMobile
          data={data}
          location={location}
          bgColor={data.prismicOverviewSection.data.background_color}
        />
      )}
    </>
  );
};

export default withPreview(OverviewPage);

export const query = graphql`
  query OverviewPage($uid: String!) {
    prismicOverviewSection(uid: { eq: $uid }) {
      _previewable
      url
      data {
        title {
          text
        }
        background_color
        sections {
          page {
            type
            document {
              ... on PrismicProjects {
                id
                type
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicPress {
                id
                type
                data {
                  title {
                    text
                  }
                  awards {
                    award {
                      html
                    }
                  }
                  body {
                    ... on PrismicPressBodyArticle {
                      id
                      slice_type
                      primary {
                        article_title {
                          html
                        }
                        pdf_download {
                          url
                        }
                        thumbnail {
                          alt
                          fluid {
                            srcWebp
                            srcSetWebp
                          }
                        }
                      }
                      items {
                        images {
                          alt
                          fluid {
                            srcWebp
                            srcSetWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicContact {
                id
                type
                data {
                  title {
                    text
                  }
                  text {
                    html
                  }
                  contact_text {
                    html
                  }
                  newsletter_text {
                    html
                  }
                  additional_text {
                    text1 {
                      html
                    }
                  }
                }
              }
              ... on PrismicPage {
                id
                type
                data {
                  title {
                    text
                  }
                  body {
                    ... on PrismicPageBodyTitle {
                      id
                      slice_type
                      primary {
                        title_slide {
                          html
                        }
                      }
                    }
                    ... on PrismicPageBodyText {
                      id
                      slice_type
                      primary {
                        text_title {
                          html
                        }
                        text {
                          html
                        }
                      }
                    }
                    ... on PrismicPageBodySingleImage {
                      id
                      slice_type
                      primary {
                        image {
                          alt
                          dimensions {
                            height
                            width
                          }
                          fluid {
                            srcWebp
                            srcSetWebp
                          }
                        }
                      }
                    }
                    ... on PrismicPageBodyImageWithText {
                      id
                      slice_type
                      primary {
                        section_number
                        section_title {
                          html
                        }
                        small_text {
                          html
                          text
                        }
                        text {
                          html
                        }
                      }
                      items {
                        image {
                          fluid {
                            srcWebp
                            srcSetWebp
                          }
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                      }
                    }
                    ... on PrismicPageBodySmallImageWithText {
                      id
                      slice_type
                      primary {
                        section_title {
                          html
                        }
                        text {
                          html
                        }
                        image {
                          fluid {
                            srcWebp
                            srcSetWebp
                          }
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
