import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Icons
import { ArrowIconSubscribe } from "../icons/arrows";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  color: #f8f1e8;
  transition: 1500ms color ease;

  order: 12;

  @media (max-width: 1140px) {
    order: 1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & .signup-text {
    margin: 0 0 28px 0;

    & p {
      margin: 0;
    }
  }

  & .thank-you-message,
  & form {
    max-width: 670px;
    margin: 0;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid ${props => props.color};
      transition: 1500ms border ease;

      margin: 0 0 5px 0;

      & input {
        width: 100%;

        padding: 0 0 2px 0;
        margin: 0;
        border: 0;

        color: ${props => props.color};
        transition: 1500ms color ease;
        color: #f8f1e8;

        font-size: 18px;
        line-height: 28px;

        @media (max-width: 767px) {
          font-size: 15px;
        }

        ::-webkit-input-placeholder {
          font-family: "domaine-sans-text-web-light", "Helvetica Neue",
            "Lucida Grande", sans-serif;

          color: #f8f1e8 !important;

          @media (max-width: 767px) {
            font-size: 15px !important;
          }
        }
        ::-moz-placeholder {
          font-family: "domaine-sans-text-web-light", "Helvetica Neue",
            "Lucida Grande", sans-serif;

          color: #f8f1e8 !important;

          @media (max-width: 767px) {
            font-size: 15px !important;
          }
        }
        :-ms-input-placeholder {
          font-family: "domaine-sans-text-web-light", "Helvetica Neue",
            "Lucida Grande", sans-serif;

          color: #f8f1e8 !important;

          @media (max-width: 767px) {
            font-size: 15px !important;
          }
        }
        :-moz-placeholder {
          font-family: "domaine-sans-text-web-light", "Helvetica Neue",
            "Lucida Grande", sans-serif;

          color: #f8f1e8 !important;

          @media (max-width: 767px) {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  & .thank-you-message {
    & p {
      margin: 0;

      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.02em;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`;

const SubmitButtonContainer = styled.div`
  & .privacy-policy-text {
    margin: 5px 0 0 0;

    & p {
      font-size: 12px;
      line-height: 20px;

      letter-spacing: 0.02em;

      & a {
        color: rgba(248, 241, 232, 0.5);
      }
    }

    @media (max-width: 767px) {
      max-width: 220px;
      margin: 25px 0 0 0;

      & p {
        font-size: 10px;
        line-height: 16px;
      }
    }

    @media (max-width: 500px) {
      max-width: 100%;
    }
  }

  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    margin: 20px 0 50px 0;

    @media (max-width: 767px) {
      margin: 20px 0 30px 0;
    }

    & p {
      margin: 0;
    }

    & svg {
      width: 34px;
      height: auto;

      margin: 0 10px 0 0;
    }

    & button {
      border: 0;
      font-size: 18px;
      line-height: 36px;
      letter-spacing: 0.02em;

      color: #f8f1e8;
      transition: 1500ms color ease, 1500ms border ease;

      order: 1;
      cursor: pointer;

      &:hover,
      &:focus,
      &:focus-within {
        transition: 250ms all ease;
      }

      &:disabled,
      &[disabled],
      &:hover,
      &:focus,
      &:focus-within {
        color: #f8f1e8;
        background-color: transparent;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

export const Mailchimp = ({ color, bgColor }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [email, setEmail] = useState(``);

  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName,
    }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setMailchimpMessage(
              "Thank you for signing up to the Dan Fink Studio mailing list."
            );
            setIsFormVisible(false);

            setFirstName(``);
            setLastName(``);
            setEmail(``);
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the Dan Fink Studio mailing list."
          );
          setIsFormVisible(false);

          setFirstName(``);
          setLastName(``);
          setEmail(``);
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const fadeOutForm = setTimeout(() => {
        const resetFormVisbility = setTimeout(() => {
          setIsFormVisible(true);
        }, 1000);
        return () => clearTimeout(resetFormVisbility);
      }, 8000);
      return () => clearTimeout(fadeOutForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer className="z-index-100" color={color}>
      {isFormVisible && (
        <>
          <form
            name="signup-form"
            id="website_signup_form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={e => handleSubmit(e)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />
            <div className="field">
              <input
                id="fName"
                name="fName"
                type="text"
                autoComplete="given-name"
                aria-required="true"
                aria-label="First Name"
                placeholder={`First Name`}
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>

            <div className="field">
              <input
                id="lName"
                name="lName"
                type="text"
                autoComplete="family-name"
                aria-required="true"
                aria-label="Last Name"
                placeholder={`Last Name`}
                onChange={e => setLastName(e.target.value)}
                value={lastName}
              />
            </div>

            <div className="field">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="E-mail"
                placeholder={formMessage === null ? `Email` : formMessage}
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </div>

            <SubmitButtonContainer color={color} buttonHoverColor={bgColor}>
              <div className="privacy-policy-text">
                <p>
                  Use of this site is subject to{" "}
                  <Link to={`/website-terms-and-conditions-of-use`}>
                    Terms of Use
                  </Link>{" "}
                  and our <Link to={`/privacy-policy`}>Privacy Policy</Link>{" "}
                  which contains information about “cookies” and analytics. When
                  you subscribe to receive emails from us, you agree that you
                  have read, understood and agreed to our{" "}
                  <Link to={`/privacy-policy`}>Privacy Policy</Link>.
                </p>
              </div>

              <div className="top-line-container">
                <button type="submit" className="submit-button" tabIndex="0">
                  <ArrowIconSubscribe />
                  Subscribe
                </button>
              </div>
            </SubmitButtonContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message " id="thank_you_message">
          <p>{mailchimpMessage}</p>
        </div>
      )}
    </EnquiryFormContainer>
  );
};
