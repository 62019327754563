import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import Modal from "react-modal";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Icons
import { Toggle } from "../icons/menu-toggle";

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 100;

  padding: 20px 40px;

  & p {
    font-size: 16px;
    line-height: 28px;

    letter-spacing: 0.02em;
    margin: 0;

    color: #f8f1e8;

    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  & button {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    padding: 20px 30px;

    & button {
      display: none;
    }
  }
`;

const MobileFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 100;

  display: none;

  @media (max-width: 767px) {
    display: block;
    padding: 20px 30px;

    & button {
      display: block;
      margin: 0 0 0 auto;
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 0;

  color: rgba(248, 241, 232, 1);

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  padding: 75px 40px 30px 40px;

  @media (max-width: 767px) {
    padding: 70px 30px;
  }

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const LightboxContainer = styled.div`
  background-color: #7a807e;
  z-index: 100;

  height: 100%;

  & > div {
    min-height: 100%;
    background-color: #7a807e;

    overflow: hidden;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  width: 33%;
  height: 75%;

  &.previous {
    left: 0;

    cursor: url("/icons/arrow-prev-light@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-prev-light@1x.png") 1x,
          url("/icons/arrow-prev-light@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-prev-light.svg"), 50 0, pointer;
  }

  &.next {
    right: 0;

    cursor: url("/icons/arrow-next-light@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-next-light@1x.png") 1x,
          url("/icons/arrow-next-light@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-next-light.svg"), 50 0, pointer;
  }

  &.disabled {
    display: none;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

const Wrapper = styled.div`
  height: 100%;

  & img {
    opacity: ${props => props.opacity};
    transition: 5000ms opacity ease;
  }
`;

Modal.setAppElement("#___gatsby");

export const LightboxGallery = ({
  content,
  isLightboxOpen,
  setLightboxOpen,
}) => {
  const slideRef = useRef();

  const galleryContent = content.items
    .filter(image => image.images.fluid !== null)
    .map((image, index) => (
      <ImageContainer key={`homepage_images_${index}`}>
        <img
          srcSet={image.images.fluid.srcSetWebp}
          src={image.images.fluid.srcWebp}
          alt={image.images.alt}
          loading={`lazy`}
        />
      </ImageContainer>
    ));

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      document.body.style.overflow = "";
      setLightboxOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isLightboxOpen}
      className="product-lighbox-modal"
      contentLabel={`Press Gallery`}
      shouldFocusAfterRender={false}
    >
      <LightboxContainer>
        <Div100vh>
          <Wrapper
            className="lightbox-wrapper"
            opacity={isLightboxOpen ? 1 : 0}
          >
            <Header>
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: content.primary.article_title.html,
                }}
              />
              <button onClick={() => setLightboxOpen(false)}>
                <Toggle fill={`#F8F1E8`} isOpen={true} />
              </button>
            </Header>
            <GalleryContainer>
              <Fade
                ref={slideRef}
                transitionDuration={1000}
                easing={`ease`}
                autoplay={false}
                infinite={true}
                prevArrow={<Arrow className="previous" />}
                nextArrow={<Arrow className="next" />}
                canSwipe={true}
              >
                {galleryContent}
              </Fade>
            </GalleryContainer>

            <MobileFooter>
              <button onClick={() => setLightboxOpen(false)}>
                <Toggle fill={`#F8F1E8`} isOpen={true} />
              </button>
            </MobileFooter>
          </Wrapper>
        </Div100vh>
      </LightboxContainer>
    </Modal>
  );
};
