import React from "react";
import styled from "styled-components";

// Components
import { Mailchimp } from "../mailchimp/mailchimp";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  position: relative;

  width: 100%;
  height: 100%;

  padding: 80px 40px;
  overflow-y: scroll;

  color: #f8f1e8;

  @media (max-width: 767px) {
    padding: 65px 30px 30px 30px;
    overflow-y: auto;
    height: auto;
  }

  & .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    & .left-column {
      max-width: 475px;

      & .content-container {
        max-width: 475px;

        & .contact-text {
          margin: 25px 0 0 0;

          & p {
            margin: 0 0 25px 0;
          }
        }

        & .single-additional-text-container {
          & p:first-of-type {
            margin-bottom: 0;
          }
          & p:last-of-type {
            margin-top: 0;
          }
        }
      }

      & .mobile-title {
        display: none;

        & h2 {
          font-size: 18px;
          line-height: 36px;
          letter-spacing: 0.15em;
          text-align: center;
        }
      }

      @media (max-width: 767px) {
        & .mobile-title {
          display: block;
        }
      }
    }

    & .right-column {
      margin: 18px 0 0 0;
      max-width: 475px;

      & .content-container {
        max-width: 475px;

        & .newsletter-text {
          margin: 0 0 30px 0;
        }
      }

      @media (max-width: 767px) {
        margin: 2em 0 0 0;
        width: 100%;
      }
    }
  }

  & .body-text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const Contact = ({ data }) => {
  const additionalText = data.additional_text.map((content, index) => (
    <div
      key={`additional_text_list_item_${index}`}
      className="single-additional-text-container"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: content.text1.html,
        }}
      />
    </div>
  ));

  return (
    <ContactContainer id="contact">
      <div className="flex">
        <div className="left-column">
          <div className="mobile-title uppercase soehne-light">
            <h2>Contact</h2>
          </div>

          <div className="content-container">
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: data.text.html,
              }}
            />
            <div
              className="contact-text"
              dangerouslySetInnerHTML={{
                __html: data.contact_text.html,
              }}
            />
            <div>{additionalText}</div>
          </div>
        </div>

        <div className="right-column">
          <div className="content-container">
            <Mailchimp />
          </div>
        </div>
      </div>
    </ContactContainer>
  );
};
