import React from "react";
import styled from "styled-components";

// Utils
import { CrossfadeContainer } from "../containers/crossfade-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const ImageWithTextSlideContainer = styled.div`
  & .content-container {
    width: 100%;
  }

  & .image-container {
    position: relative;
    width: 100%;

    & > div {
      width: 100%;
      height: 100%;

      & .single-image {
        & .inner-image-container {
          width: 100%;
        }

        & img {
          max-height: 100%;
          width: 100%;

          object-fit: contain;
          object-position: top;
        }
      }
    }
  }

  & .title-container {
    margin: 0 auto 45px auto;

    & .title-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      & .section-number {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
      }

      & p,
      & h2 {
        margin: 0;
      }

      text-align: center;

      & p {
        font-size: 12px;
        line-height: 34px;
        letter-spacing: 0.15em;
      }

      & h2 {
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 0.15em;
      }

      & .line-spacer {
        width: 60px;
        border-bottom: 1px solid ${props => props.borderColor};
        margin: 0 10px;
      }
    }
  }

  & .text-container {
    flex: 1;

    text-align: left;

    & .small-text {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.02em;

      & ul {
        padding: 0 0 0 40px;

        & li {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          &::before {
            content: "";
            margin-left: -40px;
            margin-right: 10px;

            display: inline-block;
            width: 32px;
            border-bottom: 1px solid ${props => props.borderColor};
          }
        }
      }
    }
  }
`;

export const ImageWithTextMobile = ({ content, playing }) => {
  return (
    <ImageWithTextSlideContainer
      textAlign={
        content.primary.small_text.text.length >= 1 ? `left` : `center`
      }
    >
      <div className="content-container">
        <div className="title-container">
          <div className="title-bar">
            <div className="section-number">
              <p className="">{content.primary.section_number}</p>
              {content.primary.section_number !== null && (
                <div className="line-spacer" />
              )}
            </div>
            <div
              className="section-title uppercase soehne-light"
              dangerouslySetInnerHTML={{
                __html: content.primary.section_title.html,
              }}
            />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="image-container">
          <AspectRatioImageContainer image={null} padding={71}>
            <CrossfadeContainer
              images={content.items}
              id={content.id}
              playing={true}
            />
          </AspectRatioImageContainer>
        </div>
      </div>
      <div className="content-container">
        <div className="text-container">
          <div
            className={`text-section ${content.primary.small_text.text.length >=
              1 && `grid`}`}
          >
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
            <div
              className="small-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.small_text.html,
              }}
            />
          </div>
        </div>
      </div>
    </ImageWithTextSlideContainer>
  );
};
