import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Components
import { DesignNavigation } from "./design-navigation";
import { ExperienceNavigation } from "./experience-navigation";
import { StudioNavigation } from "./studio-navigation";

const Wrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 25px 40px;

  @media (max-width: 767px) {
    padding: 0;
  }

  z-index: 200;
  color: ${props => props.textColor};

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease-in-out;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & ol.studio-link {
    padding: 0;
    margin: 0 0 0 auto;

    & li {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.15em;

      & a {
        font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        transition: 250ms color ease;

        &:hover {
          color: rgba(0, 0, 0, 0.5);
        }

        &.current {
          color: rgba(248, 241, 232, 0.5);

          &:hover {
            color: rgba(248, 241, 232, 0.5);
          }
        }
      }
    }
  }
`;

export const DesktopNavigation = ({
  section,
  goToSlide,
  textColor,
  activeSlideId,
  bgColor,
  location,
}) => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setOpacity(0);

    const fadeOutForm = setTimeout(() => {
      setOpacity(1);
    }, 500);
    return () => clearTimeout(fadeOutForm);
  }, [location]);

  return (
    <Wrapper textColor={textColor} opacity={opacity}>
      {section === `Design` && (
        <DesignNavigation
          goToSlide={goToSlide}
          activeSlideId={activeSlideId}
          bgColor={bgColor}
        />
      )}
      {section === `Experience` && (
        <ExperienceNavigation
          goToSlide={goToSlide}
          activeSlideId={activeSlideId}
          bgColor={bgColor}
        />
      )}
      {section === `Studio` && (
        <StudioNavigation
          goToSlide={goToSlide}
          activeSlideId={activeSlideId}
          bgColor={bgColor}
        />
      )}

      <ol className="desktop-navigation studio-link">
        <li className="uppercase">
          <Link
            to={`/studio`}
            className={section === `Studio` ? `current` : ``}
          >
            Studio
          </Link>
        </li>
      </ol>
    </Wrapper>
  );
};
