import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const NavigationContainer = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const List = styled.li`
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.15em;

  margin: 0 20px 0 0;
  cursor: pointer;

  font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;

  transition: 250ms color ease;

  &.current {
    color: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    color: rgba(0, 0, 0, 0.5);

    & ol {
      color: #000;
    }
  }

  & button {
    font-size: 10px;
    line-height: 21px;
    letter-spacing: 0.15em;

    cursor: pointer;

    font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    transition: 250ms color ease;

    &.current {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      color: rgba(0, 0, 0, 0.5);

      & ol {
        color: #000;
      }
    }
  }

  & ol {
    position: absolute;
    bottom: 45px;

    background-color: ${props => props.bgColor};
    opacity: 0.9;
    padding: 15px 20px 20px 20px;
    margin: 0 0 0 -20px;

    display: ${props => props.isSubMenuVisible};

    & li {
      margin: 0;
      line-height: 22px;
    }
  }
`;

export const DesignNavigation = ({
  hideProjects,
  hideDesignElements,
  bgColor,
  location,
  goToSlide,
  activeSlideId,
}) => {
  const [isProjectsMenuOpen, setIsProjectsMenuOpen] = useState(false);
  const [isDesignElementsMenuOpen, setIsDesignElementsMenuOpen] = useState(
    false
  );

  useEffect(() => {
    setIsProjectsMenuOpen(false);
    setIsDesignElementsMenuOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicPage(uid: { eq: "elements" }) {
        data {
          body {
            ... on PrismicPageBodyImageWithText {
              id
              primary {
                section_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageBodySingleImage {
              id
              slice_type
            }
            ... on PrismicPageBodyText {
              id
              slice_type
            }
            ... on PrismicPageBodyTitle {
              id
              slice_type
            }
          }
        }
      }
      prismicOverviewSection(uid: { eq: "design" }) {
        data {
          title {
            text
          }
          sections {
            page {
              type
              document {
                ... on PrismicProjects {
                  id
                  type
                }
                ... on PrismicPress {
                  id
                  type
                }
                ... on PrismicContact {
                  id
                  type
                }
                ... on PrismicPage {
                  id
                  type
                  data {
                    title {
                      text
                    }
                    body {
                      ... on PrismicPageBodyTitle {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyText {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodySingleImage {
                        id
                        slice_type
                      }
                      ... on PrismicPageBodyImageWithText {
                        id
                        slice_type
                      }
                    }
                  }
                }
                ... on PrismicProjects {
                  id
                  data {
                    title {
                      text
                    }
                    projects {
                      project {
                        url
                        document {
                          ... on PrismicProject {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const designElementsNavigation = data.prismicPage.data.body.map(
    (page, index) => {
      if (page.slice_type === "image_with_text") {
        return (
          <List
            key={`single_design_elements_nav_item_${index}`}
            onClick={() => goToSlide(page.id)}
            className={`uppercase ${
              page.id === activeSlideId.id ? `current` : ``
            }`}
          >
            {page.primary.section_title.text}
          </List>
        );
      }
    }
  );

  const navigation = data.prismicOverviewSection.data.sections.map(
    (page, index) => {
      if (page.page.document.data.title.text === "Projects") {
        const projectsMenu = page.page.document.data.projects
          .filter(project => project.project.document !== null)
          .map((project, index) => (
            <List
              key={`single_sub_nav_item_${project.project.document.id}_${index}`}
            >
              <Link to={project.project.url} activeClassName="current">
                {project.project.document.data.title.text}
              </Link>
            </List>
          ));

        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className="uppercase"
            onMouseEnter={() => setIsProjectsMenuOpen(true)}
            onMouseLeave={() => setIsProjectsMenuOpen(false)}
            isSubMenuVisible={isProjectsMenuOpen ? `block` : `none`}
            bgColor={bgColor}
          >
            {!hideProjects && <ol>{projectsMenu}</ol>}
            <Link to={`/projects`} activeClassName="current">
              {page.page.document.data.title.text}
            </Link>
          </List>
        );
      } else if (page.page.document.data.title.text === "Elements") {
        const allDesignElementsSlideIds = page.page.document.data.body.map(
          item => item.id
        );
        const firstDesignElementsSlideId = allDesignElementsSlideIds[0];

        return (
          <List
            key={`single_nav_item_${page.page.document.id}_${index}`}
            className={`uppercase ${
              allDesignElementsSlideIds.includes(activeSlideId.id)
                ? `current`
                : ``
            }`}
            onMouseEnter={() => setIsDesignElementsMenuOpen(true)}
            onMouseLeave={() => setIsDesignElementsMenuOpen(false)}
            isSubMenuVisible={isDesignElementsMenuOpen ? `block` : `none`}
            bgColor={bgColor}
          >
            {!hideDesignElements && <ol>{designElementsNavigation}</ol>}

            <button
              className="uppercase"
              onClick={() => goToSlide(firstDesignElementsSlideId)}
            >
              {page.page.document.data.title.text}
            </button>
          </List>
        );
      } else if (page.page.document.data.title.text === "Philosophy") {
        const allPhilosophySlideIds = page.page.document.data.body.map(
          item => item.id
        );
        const firstPhilosophySlideId = allPhilosophySlideIds[0];

        return (
          <List
            key={`single_nav_item_${firstPhilosophySlideId}_${index}`}
            className={`uppercase ${
              allPhilosophySlideIds.includes(activeSlideId.id) ? `current` : ``
            }`}
          >
            <button
              className="uppercase"
              onClick={() => goToSlide(firstPhilosophySlideId)}
            >
              {page.page.document.data.title.text}
            </button>
          </List>
        );
      }
    }
  );

  return <NavigationContainer>{navigation}</NavigationContainer>;
};
